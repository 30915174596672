import React from 'react'
import Vehicles from '../../components/Vehicles/vehicles'
import Header from '../../components/Header/header_en'
import Footer from '../../components/Footer/footer'
import LayoutEn from '../../components/layoutEn'
import Helmet from 'react-helmet'

const VehiclesPage = () => (
    <LayoutEn>
         <Helmet
          title="VIP CARS - Rent a Car | Luxury cars for rent"
          meta={[
            { name: 'description', content: 'VIP Cars - Rent a Car | See our vehicle (car) range. For complete pleasure, professional drivers are also available | T: +387 62 520 556.' },
          ]} >
        </Helmet>
        <div id="vehicles">
    <div className="header-back"></div>
    <Header/>
   
    <section className="section vehicles">
         <div className="container">

            <Vehicles/>
        </div>
   </section>
   <Footer/>
   </div>
   </LayoutEn>
)

export default VehiclesPage
