import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/index.scss'
import '../styles/_responsive.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight, faTimes,faMap, faPhone, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import {  } from '@fortawesome/free-regular-svg-icons'

library.add(faArrowRight, faFacebookF, faInstagram, faTwitter, faTimes, faMap, faPhone, faEnvelopeOpen)



const LayoutEn = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQueryEn {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
     
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'VIP Cars is a car rental that offers LUXURY VEHICLES & DRIVERS. Pick up your car wherever you want  in Bosnia, Croatia and Serbia. START your reservation now or call us for more information +387 62 520 556.' },
            { name: 'keywords', content: '' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div>
          <div id="loader">
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
          </div>
      
          {children}
        </div>
      </>
    )}
  />
)

LayoutEn.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutEn
