import React from 'react'
import Link from 'gatsby-link'
import image1 from "../../images/v-class-1.jpg";
import image2 from "../../images/v-class-2.jpg";
import image3 from "../../images/v-class-3.jpg";
import image4_bs from "../../images/Ponuda-luksuznih-VIP-CARS-SARAJEVO-vozila(auta).jpg";
import image4 from "../../images/Offer-of-VIP-CARS-SARAJEVO-luxury-vehicles.jpg";
import image5 from "../../images/viano.jpg";
import image6 from "../../images/volvo.jpg";
import image7 from "../../images/Ponuda-luksuznih-VIP-CARS-SARAJEVO-vozila-mercedes-350d-s-class.jpg";
import {prefix} from '../langPrefix'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import {v_class, exlusive, exciting_luxury, v_class_title, v_class_text1, v_class_text2,viano_title, viano_text, volvo_title, volvo_text, gallery_text_v_class,
    gallery_text_vito, gallery_text_volvo, gallery_text_viano, vehicles_text_bottom_p1, vehicles_text_bottom_p2, gallery_alt,gallery_text_s_class, vehicles_bottom_alt, vito_text, vito_title,
s_350d_title, s_350d_text, s350d_title, s350d_text} from '../strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function image_bottom(){
    if(prefix === "/en"){
        return   <img src={image4} alt={vehicles_bottom_alt} title={vehicles_bottom_alt}/>;
    }
    else{
        return   <img src={image4_bs} alt={vehicles_bottom_alt} title={vehicles_bottom_alt}/>;
    } 
}

class Vehicles extends React.Component{

    render(){
        return(
            <div>
           

             <div className="columns first-section">
                     <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-7 image" >
                     </div>
                     <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-5 content">
                         <h2 className="title-big">{v_class} <br /><span>{exlusive}</span> - <br/>{exciting_luxury}</h2>
                     </div>
                     <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-7 image mobile" >
                     <img src={image1} alt="VIP Cars V Class" className="mobile" />
                     </div>
                     <div animateIn='zoomIn' animateOut='zoomOut' offset="0" delay="0" animateOnce="true" className="divider"></div>
             </div>
            
             <div className="columns default-section second-section">
                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6">
                 
                    <div className="columns inner-columns">
                        <div className="column content">
                        <h3 className="title-small">{v_class_title}</h3>
                    <p className="text">{v_class_text1}</p>
                        </div>
                    </div>
                    <div className="column">
                            <div className="column button-area">
                                <Link to="/gallery-v-class" title={gallery_alt} className="">"{gallery_text_v_class}<FontAwesomeIcon icon={['fas', 'arrow-right']} aria-hidden="true"  /></Link>
                                </div>
                        </div>
                 </div>
                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6 image">
                 <img src={image2} alt="VIP Cars V Class" className="mobile" />
                 </div>
                 <div animateIn='zoomIn' animateOut='zoomOut' offset="0" delay="0" animateOnce="true" className="divider"></div>
             </div>
            
            
             <div className="columns third-section">
                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-8 image">   
                 <img src={image3} alt="VIP Cars V Class" className="mobile"/>
                 </div>
                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-4 content">
                     <p className="text">{v_class_text2}</p>
                 </div>
             </div>

             <div className="columns default-section fifth-section">
             <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6 image mobile">
                 <img src={image5} alt="VIP Cars Viano" className="mobile" />
            </div>

                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6">
                    <div className="columns inner-columns">
                        <div className="column content">
                            <h3 className="title-small">{viano_title}</h3>
                             <p className="text">{viano_text}</p>
                        </div>
                    </div>
                    <div className="column">
                            <div className="column button-area">
                                <Link to="/gallery-viano" title={gallery_alt} className="">{gallery_text_viano}<FontAwesomeIcon icon={['fas', 'arrow-right']} aria-hidden="true"  /></Link>
                                </div>
                        </div>
                 </div>
                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6 image"> 
                 </div>
                
             </div>

             <div className="columns default-section sixth-section">
                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6 image">
                 <img src={image6} alt="VIP Cars Viano" className="mobile" />
                 </div>
                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6 ">
                    <div className="columns inner-columns">
                        <div className="column content">
                            <h3 className="title-small">{volvo_title}</h3>
                            <p className="text">{volvo_text}</p>
                        </div>
                    </div>
                   <div className="column">
                    <div className="column button-area">
                        <Link to="/gallery-volvo" title={gallery_alt} className="">{gallery_text_volvo}<FontAwesomeIcon icon={['fas', 'arrow-right']} aria-hidden="true"  /></Link>
                        </div>
                   </div>
                 </div>       
             </div>
           
             <div className="columns default-section fourth-section">
                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-12 image">
                    {image_bottom()}
                </div>
                <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-12 ">
                    <div className="columns inner-columns">
                        <div className="column content">
                            <h3 className="title-small">{vito_title}</h3>
                            <p className="text">{vito_text}</p>
                        </div>
                    </div>
                   <div className="column">
                        <div className="column button-area">
                            <Link to="/gallery-vito" title={gallery_alt} className="">{gallery_text_vito}<FontAwesomeIcon icon={['fas', 'arrow-right']} aria-hidden="true"  /></Link>
                        </div>
                   </div>
                 </div>  
             </div>

            <div className="columns default-section seventh-section">
                <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6 image mobile">
                    <img src={image7} alt="VIP Cars Mercedes S 350d" className="mobile" />
                </div>

                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6">
                    <div className="columns inner-columns">
                        <div className="column content">
                            <h3 className="title-small">{s350d_title}</h3>
                             <p className="text">{s350d_text}</p>
                        </div>
                    </div>
                    <div className="column">
                            <div className="column button-area">
                                <Link to="/gallery-s-class" title={gallery_alt} className="">{gallery_text_s_class}<FontAwesomeIcon icon={['fas', 'arrow-right']} aria-hidden="true"  /></Link>
                                </div>
                        </div> 
                        </div>
                 <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column is-6 image"> 
                 </div> 
             </div>


                    <p className="text-bottom">{vehicles_text_bottom_p1} <a href="tel:+387 62 520 556">+387 62 520 556</a> {vehicles_text_bottom_p2} <a href="mailto:info@vipcars.ba">info@vipcars.ba</a></p>  
             
         </div>
        )
    }
}


export default Vehicles
