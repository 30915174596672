import React from "react";
import Link from 'gatsby-link'
import {get_tarterd, phone_alt, reserve_alt} from '../strings'
import {prefix, link_reserve} from '../langPrefix'

class Contact extends React.Component {
    render() {
      return (
            <ul className="is-center">
                <li className="">
                    <a href="tel:062 520 556" title="Get more information about our luxury cars">062 520 556</a>
                </li>
                <li className="is-highlight">
                    <Link to={prefix + link_reserve} title="Fill in the form, ask for additional information and reserve your car">{get_tarterd}
                    </Link>
                </li>
                
                <li className="en_lang"><a href ="/en" className="en-menu-item">EN</a></li>
                <li><a href ="/" className="ba-menu-item" >BA</a></li> 
            </ul>
      )
    }
  }

export default Contact;