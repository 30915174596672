import React from 'react'
import Logo from './logo_en'
import ScrollToTop from 'react-scroll-up'
import Navigation from './navigation'
import Link from 'gatsby-link';
import Contact from './contact_en'
const Header = ({ siteTitle }) => (
  
<section className="section header is-fixed is-full-width is-padding-0">
    <ScrollToTop showUnder={10}
            style={{
                zIndex: 99,
                top:0,
                left:0,
                height:'140px',
                background: 'none',
                cursor: 'default'
            }}>
    <div className="header-back">
    </div>
    </ScrollToTop>
  


  <div className="container is-flex">
      <div className="logo is-left">
          <Logo/>
      </div>

      <div className="navbar web is-left">
          <Navigation/>
      </div>

      <div className="contact-box is-right">
          <Contact/>
      </div>
    <a className="menu-button" tabindex="1"></a>                   
    <div className="mobile-navigation">
        <div className="navbar">
            <Navigation/>
            <a className="menu-button-close"></a>
            <div className="contact-box">
                <Contact/>
            </div>
        </div> 
    </div>
    
  </div>

</section>

)

export default Header
