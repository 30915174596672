import React from "react";

import Link from 'gatsby-link'
import {logo_alt} from '../strings'
import {prefix} from '../langPrefix'
import logo from "../../images/VIP-CARS-SARAJEVO–Rent-a-Car-luksuzna-auta.png";
function Logo() {
    return <Link to={prefix + "/"} ><img src={logo} alt="VIP CARS – Luxury Cars for Rent" title="VIP CARS – Luxury Cars for Rent" /></Link>;
}

export default Logo;